<template>
  <div>
    <b-card >
      <b-row align-h="end">
        <b-col cols="8"/>
        <b-col cols="2">
            <div class="d-flex justify-content-end py-1">
              <!--         v-if="items.length > 0"-->
              <div
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
              >
                <b-dropdown
                    boundary="viewport"
                    class="w-100"
                    style="z-index: 13"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                    dropdown
                >
                  <template #button-content>
                    <span> <uil-columns /> Columns </span>
                  </template>
                  <b-dropdown-form class="w-100">
                    <b-form-group>
                      <template #label>
                        {{ baseSpecSelectedAll }}
                        <b-form-checkbox
                            v-model="allSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="toggleAllDrop"
                        >
                          {{ allSelected ? 'Deselect All' : 'Select All' }}
                        </b-form-checkbox>
                        <b-dropdown-divider style="margin-top:3px" />
                      </template>
                      <b-form-checkbox
                          v-for="(f, index) in headers.filter((h)=>h.key!=='element_name'&&h.key!=='uid')"
                          :key="'check' + index"
                          v-model="displayColumnsCheck"
                          :value="f.key"
                      >{{ f.label }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-dropdown-divider />
                    <div style="display: flex; align-items: end; justify-content: end">
                      <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols()"
                      >Reset
                      </b-button>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
          </div>
        </b-col>
        <b-col cols="2" class="my-1" align-self="end" style="text-align: end">
          <div
            class="d-flex align-items-center justify-content-end"
            align-self="end"
            style="text-align: end"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                class="d-inline-block"
                placeholder="Search"
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <!-- <v-select v-model="filter" :options="statusOptions" class="invoice-filter-select" placeholder="Select Status">
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                            {{ label }}
                        </span>
                    </template>
                </v-select> -->
          </div>
        </b-col>
      </b-row>
      <div>
        <b-table
          :busy="isBusy"
          responsive="sm"
          :fields="headersFiltered"
          :per-page="perPage"
          :items="elements"
          :current-page="currentPage"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(total_price)="data">
            {{
              "$" + Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </template>
          <template #cell(unit_delivery_w_install)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(supply_price)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(unit_cost)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(other_profit_unit)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(rebate_unit)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(verve_profit_unit)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
        </b-table>
        <b-row style="float: right">
          <b-col cols="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {UilColumns} from "@iconscout/vue-unicons";
import {
  BTable,
  BCol,
  BRow,
  BPagination,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend, BDropdownDivider, BButton, BFormCheckbox, BDropdownForm, BDropdown, BFormGroup,
} from "bootstrap-vue";
export default {
  components: {
    BFormGroup, BDropdown, BDropdownForm, BFormCheckbox, BButton, BDropdownDivider,
    BTable,
    BCol,
    BRow,
    BPagination,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    UilColumns
  },
  data() {
    return {
      headers: [
        {
          key: "uid",
          label: "UID",
          sortable: true,
        },
        {
          key: "element_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "spec_id",
          label: "Spec Id",
          sortable: true,
        },
        {
          key: "specification_key",
          label: "Specification Key",
          sortable: true,
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
        },
        {
          key: "unit_cost",
          label: "COGS / Unit",
          sortable: true,
        },
        {
          key: "supply_price",
          label: "Price / Unit",
          sortable: true,
        },
        {
          key: "unit_delivery_w_install",
          label: "Unit Delivery and Install",
          sortable: true,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
        {
          key: "other_profit_unit",
          label: "Other Profit Unit",
          sortable: true,
        },
        {
          key: "rebate_unit",
          label: "Rebate Profit Unit",
          sortable: true,
        },
        {
          key: "verve_profit_unit",
          label: "Verve Profit Unit",
          sortable: true,
        },
      ],
      elements: [],
      filter: null,
      isBusy: false,
      perPage: 15,
      currentPage: 1,
      totalRows: 1,
      displayColumnsCheck:[
        'uid',
        'element_name',
        'spec_id',
        'specification_key',
        'category',
        'unit_cost',
        'supply_price',
        'unit_delivery_w_install',
        'total_price',
      ],
      allSelected:false
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <=11) {
        this.allSelected = false
      } else {
        this.allSelected = true
      }
    },
    headersFiltered(){
      return this.headers.filter(header => this.displayColumnsCheck.includes(header.key));
    },
  },
  mounted() {
    this.getElements();
  },
  methods: {
    getElements() {
      let vm = this;
      vm.isBusy = true;
      this.$http
        .get(`/elements`)
        .then((response) => {
          let { data } = response;
          vm.elements = data;
          vm.totalRows = vm.elements.length;
        })
        .catch((error) => {
          this.showToast("danger", "Unauthorized");
          this.$router.push({
            path: "/",
          });
        });
      vm.isBusy = false;
    },
    resetCols() {
      this.displayColumnsCheck = [
        'uid',
        'element_name',
        'spec_id',
        'specification_key',
        'category',
        'unit_cost',
        'supply_price',
        'unit_delivery_w_install',
        'total_price',
      ]
    },
    toggleAllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = [
          'uid',
          'element_name',
        ]
        if (this.headers.length > 0) {
          this.headers.map(f => {
            this.displayColumnsCheck.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheck = ['uid',
          'element_name',]
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
